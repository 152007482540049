import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const Contact = (props)=>  {


    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);




        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Term & Conditions</strong></div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{width : "99% " ,padding : "80px 30px"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div style={{fontWeight :"bold"}}>
                                <h2>Al meer Rent a Car Karachi Terms and Conditions</h2>

<p>By accessing or using the services provided by Al meer Rent a Car Karachi, you agree to comply with and be bound by the following terms and conditions:</p>

<h3>1. Use of Services</h3>
<p>You agree to use Al meer Rent a Car Karachi services for lawful purposes and in accordance with all applicable laws and regulations. You are solely responsible for any content you generate while using our services.</p>

<h3>2. Booking and Payments</h3>
<p>Booking a vehicle through Al meer Rent a Car Karachi is subject to availability. Payments for services must be made through authorized channels. Any additional charges, including taxes and fees, will be clearly communicated before confirmation.</p>

<h3>3. Cancellation and Refunds</h3>
<p>Cancellation policies are outlined during the booking process. Refunds, if applicable, will be processed in accordance with our refund policy. Note that certain bookings may be non-refundable.</p>

<h3>4. User Account Security</h3>
<p>You are responsible for maintaining the security of your account information, including your password. Al meer Rent a Car Karachi will not be liable for any unauthorized access to your account.</p>

<h3>5. Privacy Policy</h3>
<p>Your use of our services is also governed by our Privacy Policy. By using our services, you consent to the practices outlined in the Privacy Policy.</p>

<h3>6. Limitation of Liability</h3>
<p>Al meer Rent a Car Karachi shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of our services.</p>

<h3>7. Modification of Terms</h3>
<p>Al meer Rent a Car Karachi reserves the right to modify these terms and conditions at any time. Changes will be effective immediately upon posting. Your continued use of the services after changes constitute your acceptance of the revised terms.</p>

<h3>8. Termination</h3>
<p>Al meer Rent a Car Karachi reserves the right to terminate or suspend your account and access to services at any time, with or without cause.</p>

<p>For more details, please refer to our complete Terms and Conditions. If you have any questions or concerns, contact us at [contact email/phone].</p>

                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    
}


export default Contact;